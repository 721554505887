import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

import {
  getCountry,
  getLanguageAndCountry,
  getPercents,
  getTotalGender,
} from './utils.js';

export default {
  name: 'BrandAnalyticsFans',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    audienceInRange: {
      required: true,
      type: Object,
    },
  },

  computed: {
    cities() {
      return this.getTotalByGroup(this.audienceInRange.cities);
    },

    countries() {
      return this.getTotalByGroup(this.audienceInRange.countries);
    },

    genderAge() {
      return this.getTotalByGroup(this.audienceInRange.genderAge);
    },

    locale() {
      return this.getTotalByGroup(this.audienceInRange.locale);
    },

    totalWomen() {
      return getTotalGender('F.', this.genderAge);
    },

    totalMen() {
      return getTotalGender('M.', this.genderAge);
    },

    genderAgeChartData() {
      const labels = Object.keys(this.genderAge)
        .filter(ga => !ga.includes('U.'))
        .map(ga => ga.substring(2))
        .reduce((ac, ga) => {
          if (!ac.includes(ga)) {
            ac.push(ga);
          }

          return ac;
        }, [])
        .sort((a, b) => {
          return a.localeCompare(b);
        });

      const totalGender = this.totalWomen + this.totalMen;

      return labels.map(label => {
        const female = Object.entries(this.genderAge).find(gender =>
          gender[0].includes(`F.${label}`)
        );

        const male = Object.entries(this.genderAge).find(gender =>
          gender[0].includes(`M.${label}`)
        );

        const data1 = getPercents(totalGender, female ? female[1] : 0);
        const data2 = getPercents(totalGender, male ? male[1] : 0);

        return {
          data1,
          data2,
          label,
        };
      });
    },

    countryChartData() {
      return Object.entries(this.countries)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(country => {
          return {
            color: '#DAF1EF',
            data: country[1],
            label: getCountry(country[0]),
          };
        });
    },

    citiesChartData() {
      return Object.entries(this.cities)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(city => {
          return {
            color: '#DAF1EF',
            data: city[1],
            label: city[0],
          };
        });
    },

    languageChartData() {
      return Object.entries(this.locale)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(language => {
          const lc = getLanguageAndCountry(language[0]);

          return {
            color: '#65C6BD',
            data: language[1],
            label: `${lc.language} (${lc.country})`,
          };
        });
    },

    steps() {
      const width = window.innerWidth;

      return width > 580 ? 9 : 5;
    },
  },

  methods: {
    getTotalByGroup(obj) {
      return (obj || []).reduce((ac, val) => {
        Object.keys(val).forEach(key => {
          if (ac[key]) {
            ac[key] += val[key];
          } else {
            ac[key] = val[key];
          }
        });

        return ac;
      }, []);
    },
  },
};
