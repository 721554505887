var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.audienceInRange)?_c('section',[_c('ComponentWithFloatingIconDownload',{attrs:{"component-name":"BrandAnalyticsFansCards","componentProps":{
      icon: 'gender',
      title: 'Fans Gender and Age',
      chartBipolarProps: { values: _vm.genderAgeChartData },
      genderAgeProps: { women: _vm.totalWomen, men: _vm.totalMen },
    }}}),_vm._v(" "),_c('div',{class:_vm.$style.doubleCards},[_c('div',{class:_vm.$style.card},[_c('ComponentWithFloatingIconDownload',{attrs:{"component-name":"BrandAnalyticsFansCards","componentProps":{
          icon: 'country',
          title: 'Fans Top Countries',
          chartHorizontalBarWithStepProps: {
            dataSet: _vm.countryChartData,
            steps: _vm.steps,
            style: 'width:100%',
          },
        }}})],1),_vm._v(" "),_c('div',{class:_vm.$style.card},[_c('ComponentWithFloatingIconDownload',{attrs:{"component-name":"BrandAnalyticsFansCards","componentProps":{
          icon: 'city',
          title: 'Fans Top Cities',
          chartHorizontalBarWithStepProps: {
            dataSet: _vm.citiesChartData,
            steps: _vm.steps,
            style: 'width:100%',
          },
        }}})],1)]),_vm._v(" "),_c('ComponentWithFloatingIconDownload',{attrs:{"component-name":"BrandAnalyticsFansCards","componentProps":{
      icon: 'language',
      title: 'Fans Top Languages',
      chartHorizontalBarWithStepProps: {
        dataSet: _vm.languageChartData,
        inline: true,
        steps: _vm.steps,
        style: 'width:100%',
      },
    }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }